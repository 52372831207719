import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Loader from '../../shared/loader'
import Product from '../grid/product'

const useFetch = ( params ) => {
	
	const [products, setProducts] = useState( [] );
	const [locations, setLocations] = useState( [] );
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( false );
    
	useEffect(() => {
		const fetchProducts = async () => {
			
			setLoading( true );
			setError( false );
			
			try {
				const response = await axios.post( '/products/trending', params )
				setLocations(response.data.locations);
				setProducts(response.data.products);
				setLoading( false );
			} catch (error) {
				setError(error.message);
			}
		};
		
		fetchProducts();
		
    }, [params]);	
    
    return { products, locations, loading, error };
	
}

const TrendingProducts = ({ config }) => {
	
	const defaultRequest = {
		authenticity_token: config.token,
		type: config.type,
		product_ids: config.productIds!= undefined ? config.productIds : [],
		location_id: config.locationId != undefined && config.locationId > 0 ? config.locationId : 0,
		section_id: config.sectionId != undefined && config.sectionId > 0 ? config.sectionId : 0,
		sub_category_id: config.subCategoryId != undefined && config.subCategoryId > 0 ? config.subCategoryId : 0,
		limit: config.limit != undefined && config.limit > 0 ? config.limit : 8
	}

	const [ request, setRequest ] = useState( defaultRequest );
	const { products, locations, loading, error } = useFetch( request );
	
	const handleLocationChange = ( event ) => {
		if( event.target.value.length > 0 ) {
			const url = '/' + config.path + '/' + event.target.value;
			history.pushState(undefined, 'Home', url);
			window.location.replace(url);
		}
	}
	
	const generate_path = ( product ) => {
		let parsed_path = config.path.replace( '#LOCATION', product.location_slug )
		return `/${parsed_path}`
	}
	
	return (
		<div>
		{ loading || products.length == 0 ? (
			<Loader />
		):(
			<div>
				{config.title &&  <h3 className="title">{config.title}</h3>}
				{locations.length > 0 ? (
				<select className="popularity-dropdown" id="popularity-select" name="popularity"  onChange={handleLocationChange}>
					<option value="">Choose a location</option>
					{locations.map( location => (
						<option value={location.slug} key={`loc`+location.id}>{location.name}</option>
					))}
				</select>
				) : null }
				<div className="product-grid trending">	
					<div className="grid-x grid-padding-x small-up-1 medium-up-2 large-up-3">
						{products.map( product => (
							<Product 
								product={product} 
								key={`pro-` + product.id} 
								cdnPath={config.cdn} 
								path={generate_path( product )}
								highlightItem={() => { return false }}
							/>
						))}
					</div>
				</div>
				{config.button && <div className="align-content horizontal center"><a href={config.button.url} className="button plain border fit-content">{config.button.text}</a></div>}
			</div>
		)}
		</div>
	);
}



document.addEventListener('DOMContentLoaded', () => {

	var trending_list = document.querySelectorAll('.cpt-product-trending'); // returns NodeList
	var trending = [...trending_list]; // converts NodeList to Array

	trending.forEach(grid => {
		const config = JSON.parse(grid.getAttribute('config'));
		ReactDOM.render(
			<TrendingProducts config={config} />,
			grid,
		)
	});
})
